<template>
  <PullRefresh :onRefresh="onRefresh" :config="refreshConfig" :pullStatus="getPullStatus">
    <div id="init">
      <div class="init-inner">
        <!--  -->
        <div class="v-sider show">
          <div class="v-sider-inner flex flex-column">
            <div class="logo">
              <img src="@/assets/img/logo.webp" alt="">
            </div>
            <VNavbar class="flex-1"></VNavbar>

            <!-- <SwitchLang></SwitchLang> -->
            <div @click="onLogout" class="flex align-center logout"> 
              <a-icon type="logout" style="font-size: 24px; margin-right: 10px; color: #fff" />
              {{ $t("Common.Sign Out") }}
            </div>
          </div>
        </div>

        <div class="v-layout">
          <VHeader>
            <a-icon slot="icon" type="menu-unfold" style="font-size: 18px; color: #fff; margin-right: 10px;"
              class="showIcon" @click="showNavBar" />
          </VHeader>

          <div class="init-content">
            <RouterView />
          </div>
        </div>
      </div>

      <a-drawer title="" placement="left" :closable="false" :visible="visible" @close="onClose" width="250px">
        <div class="logo">
          <img src="@/assets/img/logo.webp" alt="">
        </div>
        <VNavbar></VNavbar>

        <!-- <SwitchLang></SwitchLang> -->
        <div @click="onLogout" class="flex align-center logout"> 
          <a-icon type="logout" style="font-size: 20px; margin-right: 10px;" />
          {{ $t("Common.Sign Out") }}
        </div>
      </a-drawer>
    </div>
  </PullRefresh>
</template>

<script>
import VNavbar from './navbar.vue';
import VFooter from './footer.vue';
import VHeader from './header.vue';
import Assets from '@/components/assets.vue';
import SwitchLang from '@/components/switchLang.vue';
import PullRefresh from '@/components/Pull-refresh/index.vue';
export default {
  components: {
    VNavbar,
    VFooter,
    VHeader,
    Assets,
    SwitchLang,
    PullRefresh
  },
  data() {
    return {
      collapsed: false,
      hide: window.innerWidth > 576 ? true : false,
      visible: false,
      refreshConfig: {
        errorLabel: this.$t('Common.加载中'),
        startLabel: this.$t('Common.加载中'),
        readyLabel: this.$t('Common.加载中'),
        loadingLabel: this.$t('Common.加载中')
      },
      pullPage: [
        '/home'
      ]
    }
  },
  computed: {
    getPageTitle() {
      return this.$store.state.pageTitle
    },
    getPullStatus() {
      let res = true;
      let current = this.$route.path;
      res = this.pullPage.includes(current);
      return res;
    }
  },
  methods: {
    onCollapse(collapsed, type) {
      // // console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      // // console.log(broken);
    },
    onMouseEnter() {
      this.collapsed = false;
    },
    onMouseLeave() {
      this.collapsed = true;
    },
    showNavBar() {
      // console.log('visible :>> ', this.visible);
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onLogout() {
      localStorage.removeItem('token');
      this.$router.replace('/login');
      
      this.$store.state.socketObj.close();
    },
    onRefresh() {
      this.$myEvent.$emit('refresh');
      return new Promise((resolve, reject)=> {
        setTimeout(()=> {
          resolve();
        }, 0.5 * 1000)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#init {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.init-inner {
  height: 100%;
  display: flex;
  overflow: hidden;
}

.ant-layout {
  height: 100vh;
  background-color: transparent;
}

.ant-layout-sider {
  border-radius: 15px;
  background-color: var(--primary);
}

.logo {
  padding: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  img {
    width: 100%;
  }
}

.page-title {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px 24px 8px 8px;
  color: var(--pageTitle);
  font-weight: 900;
  font-size: 18px;
}

.show {
  display: block;
}

.showIcon {
  display: none;
}

::v-deep .ant-drawer-body {
  padding: 15px 0;
}

::v-deep .ant-drawer-content {
  padding: 0 15px;
  background: linear-gradient(to right, #000, rgba(0,0,0, 0.7));
}
::v-deep .ant-drawer-content-wrapper {
  border-radius: 0 15px 15px 0;
  overflow: hidden;
}


.v-layout {
  box-sizing: border-box;
  height: 100vh;
  padding: 15px 40px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}


.v-sider {
  border-right: 1px solid #fff;
  border-radius: 0;
  padding: 30px;
  position: relative;
  width: 330px;
}

.logout {
  position: absolute;
  top: 85vh;
  left: 30px;
  margin-top: 50px; 
  color: #fff; 
  cursor: pointer;
  padding-left: 20px;
  font-size: 18px;
}


@media screen and (max-width: 576px) {
  .show {
    display: none;
  }

  .showIcon {
    display: block;
  }

  .v-layout {
    padding: 15px 15px 80px;
  }

  .logout {
    top: 75vh;
  }
}
</style>
