<template>
  <div class="header">
    <a-layout-header :style="{ background: 'transparent', padding: 0 }">
      <div class="header-inner flex align-center justify-between">
        <div class="logo-green flex align-center">
          <slot name="icon"></slot>
          <template v-if="$route.path === '/home'">
            {{ $t("Common.Welcome") }}, <span> {{ getUserName }} </span>.
            <!-- {{ $t("Common.Welcome") }}, <span class="name flex-1"> 1232131231231231232 </span>. -->
          </template>
        </div>

        <div class="header-right">
          <div class="currentTime">{{ serverTime }}

            <div class="time">
              (UTC+2)
            </div>
          </div>
          <SwitchLang></SwitchLang>
        </div>
      </div>
    </a-layout-header>
  </div>
</template>

<script>
import SwitchLang from "@/components/switchLang.vue";
import { webUserGetServeTime } from "@/api";
import dayjs from "dayjs";

export default {
  components: {
    SwitchLang,
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "en-US",
      serverTime: "",
      currentTime: "",
    };
  },
  computed: {
    getUserInfo() {
      return this.$store.state.UserInfo;
    },
    getUserName() {
      return this.getUserInfo.userName;
    },
    getUserLevel() {
      return this.getUserInfo.userLevel;
    },
    getLevelName() {
      let name = "";
      if (this.getUserLevel) {
        name = this.$t("level." + this.getUserLevel);
      }
      return name;
    },
  },
  methods: {
    onLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("loginId");
      this.$router.replace("/login");
    },
    switchLang(value) {
      localStorage.setItem("lang", value);
      this.lang = value;
      window.location.reload();
    },
    getCurrentTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.currentTime += 1000;
          this.serverTime = dayjs(this.currentTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const token = localStorage.getItem("token");
          if (!token) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async getServeTime() {
      const { data } = await webUserGetServeTime({});
      if (data.code === 20000) {
        // // console.log("webUserGetServeTime :>> ", data.data);
        this.serverTime = data.data;
        this.currentTime = new Date(data.data).getTime();
        this.getCurrentTime();

        setTimeout(() => {
          const token = localStorage.getItem("token");
          if (token) {
            this.getServeTime();
          }
        }, 30 * 60 * 1000);
      }
    },
  },
  created() {
    this.getServeTime();
  },
};
</script>

<style lang="scss" scoped>
.header {
  .logo-green {
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .right-dropdown {
    img {
      height: 35px;
      margin-right: 10px;
      border-radius: 5px;
    }

    .name {
      color: #fff;
    }
  }

  .header-inner {
    height: 100%;
  }
}

.dropUser {
  padding: 10px 0;
  border-bottom: 0.5px solid var(--border);
  font-weight: bold;

  .level {
    img {
      height: 35px;
      margin-right: 10px;
    }
  }
}

.ant-dropdown-menu {
  padding: 15px;
}

.ant-dropdown-menu-item {
  margin: 7px 0;
  width: 220px;

  &:hover {
    a {
      color: var(--headerDropHover) !important;
    }
  }
}

.ant-dropdown-menu-submenu-arrow {
  display: none !important;
}

.menu-item {
  display: flex;
  width: 196px;
}

.flag {
  margin-left: 10px;
  height: 20px;
  border-radius: 3px;
}

.show {
  display: none;
}

.ant-layout-header {
  height: 120px !important;
}

.currentTime {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;

  .time {
    display: inline-block;
  }
}

.header-right {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .header {
    .logo-green {
      font-size: 16px;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .show {
    display: block;
    margin-right: 10px;
  }

  .currentTime {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    .time {
      display: block;
    }
  }
}
</style>
