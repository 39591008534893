<template>
  <div class="assets">
    <a-row :gutter="1">
      <a-col :xs="24" :sm="12" :md="8" :xl="8" v-for="(item, index) in list" :key="index">
        <div class="assets-item">
          <div class="assets-inner">
            <div class="left">
              <div class="name">{{ $t("coin." + item.coinName) }}</div>
              <div class="available">
                {{ getAssetObj[item.coinName] || 0 }}
                {{ signCoinName[item.coinName] }}
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { coinName: "USDT_POINT" },
        { coinName: "ETH_POINT" },
        { coinName: "BTC_POINT" },
      ],
      signCoinName: {
        USDT_POINT: "USDT",
        BTC_POINT: "BTC",
        ETH_POINT: "ETH",
      },
    };
  },
  computed: {
    getAssetObj() {
      let obj = {};
      let AssetsList = this.$store.state.AssetsList;

      if (AssetsList.length > 0) {
        AssetsList.forEach((item) => {
          obj[item.coinName] = this.fixedNumber(this.toNonExponential(item.assetsAvailable), item.coinName);
        });
      }
      return obj;
    },
  },
  methods: {
    toNonExponential(num) {
      if(num !== 0) {
        var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
        return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
      } else {
        return 0
      }
    },
    fixedNumber(val, type) {
      function formatDecimal(num, decimalPlaces) {
        var str = num.toString();
        var decimalIndex = str.indexOf(".");

        // 如果没有小数点，补充小数点和 0
        if (decimalIndex === -1) {
          str += ".";
          for (var i = 0; i < decimalPlaces; i++) {
            str += "0";
          }
        } else {
          var currentDecimalPlaces = str.length - decimalIndex - 1;
          var difference = decimalPlaces - currentDecimalPlaces;

          // 补充缺少的0
          for (var j = 0; j < difference; j++) {
            str += "0";
          }
        }

        // 返回截取后的字符串
        return str.substr(0, str.indexOf(".") + decimalPlaces + 1);
      }
      if(!val) {
        return 0
      }
      if (val !== 0) {
        if (type === "USDT_POINT") {
          return formatDecimal(val, 2);
        } else if (type === "ETH_POINT") {
          return formatDecimal(val, 5);
        } else {
          return formatDecimal(val, 7);
        }
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assets {
  padding: 15px 0;
}

.assets-item {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #fff;

  .assets-inner {
    padding: 20px 20px 20px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .assets-img {
    height: 25px;
  }

  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .name {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
  }

  .available {
    line-height: 40px;
    font-size: 34px;
    color: #fff;
  }

  .history {
    color: #ffbd59;
    margin-top: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .assets-item {
    margin-bottom: 10px;

    .assets-inner {
      padding: 10px 15px;

    }

    .assets-img {
      height: 15px;
    }

    .icon {
      width: 40px;
      height: 40px;
    }

    .right {
      align-items: center;
    }

    .name {
      font-size: 14px;
    }

    .available {
      line-height: 30px;
      font-size: 20px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}
@media screen and (max-width: 768px) {
  .assets-item {
    margin-bottom: 10px;

    .assets-inner {
      padding: 10px 15px;

      .left {
        display: flex;
        align-items: center;
        justify-content: space-between
      }
    }

    .assets-img {
      height: 15px;
    }

    .icon {
      width: 40px;
      height: 40px;
    }

    .right {
      align-items: center;
    }

    .name {
      font-size: 14px;
    }

    .available {
      line-height: 30px;
      font-size: 20px;
    }
  }

}
</style>
