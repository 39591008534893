export default class WS {
  url = "";
  ws = null;
  onmessage = (msg)=> {};
  bindId = "";
  heartTime = null;
  timerOutObj = null;
  reconnectStatus = true;

  constructor(url, bindId) {
    if(url) {
      this.url = url;
      this.bindId = bindId;
      this.init();
    }
  }

  init = () => {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = this.onopen;
    this.ws.onmessage = (event) => this.onmessage(event.data);
    this.ws.onclose = this.onclose;
    this.ws.onerror = this.onerror;
  };

  close = () => {
    this.ws && this.ws.close();
  };

  // open
  onopen = () => {
    // console.log("socket connect success");
    this.send('sub', this.bindId);

    this.heartTime = setInterval(() => {
      this.send('heartbeat', this.bindId);
    }, 5000)
  };

  send = (type, bindId) => {
    let msg = JSON.stringify({
      type,
      bindId
    });
    this.ws.send(msg)
  }

  // close
  onclose = () => {
    // console.log("socket on close");

    clearTimeout(this.timerOutObj);
    clearInterval(this.heartTime);
    // if(!this.timerOutObj) {
    //   this.timerOutObj = setTimeout(()=> {
    //     this.ws.close();
    //     clearInterval(this.heartTime);
    //     this.heartTime = null;
    //     this.init();
    //     this.timerOutObj = null;
    //   }, 3000)
    // }
  };
  // error
  onerror = () => {
    // console.log('socket on error');

    if(!this.timerOutObj) {
      this.timerOutObj = setTimeout(()=> {
        this.ws.close();
        clearInterval(this.heartTime);
        this.heartTime = null;
        this.init();
        this.timerOutObj = null;
      }, 3000)
    }
  };
}