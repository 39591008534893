<template>
  <div id="app">
    <a-spin :spinning="getLoading">
      <a-config-provider :locale="localData[locale]">
        <router-view />
      </a-config-provider>
    </a-spin>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
import koKR from 'ant-design-vue/lib/locale-provider/ko_KR';
import jaJP from 'ant-design-vue/lib/locale-provider/ja_JP';
export default {
  data() {
    return {
      locale: localStorage.getItem('lang') || 'en-US',
      localData: {
        'zh-CN': zhCN,
        'en-US': enUS,
        'ko-KP': koKR,
        'ja-JP': jaJP,
      }
    }
  },
  computed: {
    getLoading() {
      return this.$store.state.loading
    }
  },
  created() {
    // // console.log('localData[locale] :>> ', this.localData[this.locale]);

    // this.$message.error({
    //   content: '1',
    //   duration: 100000
    // })

    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('storeActionUserInfo');
      this.$store.dispatch('storeActionAssetsList');
      this.$store.dispatch('storeActionReturnWallet');
      this.$store.dispatch("storeSocketConnect");
    }
  }
}
</script>

<style>
@import url(./common/common.scss);


html {
  --primary: transparent;
  --navbar: #fff;
  --border: #fff;
  --headerDropHover: #0ba637;
  --pageTitle: #071437;
  --cardBorder: #DBDFE9;
  --success: #17C653;
  --labelColor: #78829D;
  --gray: #99A1B7;
  --tip: #99A1B7;
}

#app {
  box-sizing: border-box;
  /* padding: 15px 30px 15px 15px; */
  width: 100vw;
  height: 100vh;
  /* background: url(./assets/bg.jpg); */
  background: linear-gradient(to right, #000, rgba(0,0,0, 0.7));
  color: #f1f1f1;
  overflow: hidden;
}

div {
  box-sizing: border-box;
}

.ant-card {
  border-radius: 5px !important;
  margin-bottom: 15px !important;
  background: transparent !important;
}

.pagination {
  padding: 15px 0;
  text-align: right;
}

.ant-card-head {
  border-bottom: 0 !important;
}

.ant-card-head-title {
  height: 70px;
  padding: 0 !important;
  font-weight: bold !important;
  display: flex !important;
  align-items: center;
  color: #fff;
}

.ant-card-body {
  padding-top: 0 !important;
  color: #fff !important;
}

.ant-form-item-label > label {
  font-size: 18px !important;
  color: #fff !important;
}


.ant-input {
  width: 100%;
  background: #323232 !important;
  border-color: #fff !important;
  color: #fff !important;
  border-radius: 0 !important;
  border: 0 !important;

  &::-webkit-input-placeholder {
    font-size: 12px;
  }
}

.ant-select-selection {
  background-color: #323232 !important;
  border: 0 !important;
}

.ant-select-selection-selected-value {
  color: #fff;
  font-size: 14px;
}

.ant-input-password-icon {
  color: #fff;
}

.ant-select-arrow {
  color: #fff !important;
}

.ant-modal-header {
  background-color: #000 !important;
  color: #fff !important; 
  border-bottom: 0 !important;
}
.ant-modal-title {
  background-color: #000 !important;
  color: #fff !important; 
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 40px !important;
}

.ant-modal-content {
  background-color: #000 !important;
  color: #fff !important;
}

.ant-modal-close-icon {
  color: #fff !important;
}

.terms {
  font-size: 12px;
  color: #fff;
  line-height: 12px;
  span {
    color: #ff0000;
    border-bottom: 1px solid #ff0000;
  }
}

.submit {
  background-color: #323232 !important;
  border: none !important;
  border-radius: 10px !important;
  height: 50px !important;
  padding: 0 20px !important;
}

.submit[disabled] {
  background-color: #f5f5f5 !important;
}


.ant-select-dropdown {
  background-color: #000 !important;
  color: #fff !important;
}

.ant-select-dropdown-menu-item-selected {
  background-color: #403d3c !important;
  color: #fff !important;
}

.ant-select-dropdown-menu-item {
  color: #fff !important;
}

@media screen and (max-width: 576px) {
  #app {
    padding: 0;
  }

  .ant-card-head-title {
    height: auto;
    padding: 10px 0 !important;
    line-height: 40px;
  }

  .terms {
    font-size: 10px;
    line-height: 12px;
  }
}
</style>
