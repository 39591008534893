<template>
  <div class="footer">
    <a-layout-footer style="background: transparent; padding-left: 0;">
      2024 © www.zchp.vip | All Rights Reserved. 
      <a href="mailto:support@zchp.vip" target="_blank">
        Support: support@zchp.vip
      </a>
    </a-layout-footer>

    <div class="footer-h5 show">
      <div class="inner flex">
        <div class="flex-1 item" v-for="(item, index) in list" :key="index" @click="$router.push(item.path)">
          <a-icon :type="item.icon" style="color: #27173E;" />
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { label: this.$t('Navbar.主页'), icon: 'home', path: '/home'  }, 
        { label: this.$t('Navbar.活动'), icon: 'project', path: '/activity'  }, 
        { label: this.$t('Navbar.理财'), icon: 'wallet', path: '/deposit'  }, 
        { label: this.$t('Navbar.记录'), icon: 'container', path: '/assets-records'  }, 
        { label: this.$t('Navbar.账号管理'), icon: 'setting', path: '/account' }, 
      ]
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
a {
  color: #252F4A;
}

.show {
  display: none;
}

@media screen and (max-width: 576px) {
  .show {
    display: block;
  }

  .footer-h5 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    padding: 7px 0;
    border-top: 1px solid var(--cardBorder);
    background-color: #fff;

    .inner {
      width: 100%;
    }
    .item {
      text-align: center;
      cursor: pointer;
      div {
        margin-top: 5px;
        font-size: 11px;
        color: var(--primary);
      }
    }
  }
}
</style>