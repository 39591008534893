<template>
  <div class="navbar">
    <a-menu mode="inline" :default-selected-keys="[defaultKey]">
      <template v-for="(item, index) in navList">
        <a-menu-item :key="index" v-if="!item.subMenu" @click="onClickMenu(item)">
          <div>
            <!-- <a-icon :type="item.icon" /> -->
            <span class="nav-text">{{ item.label }}</span>
          </div>
        </a-menu-item>
        <a-sub-menu :key="index + ''" v-else>
          <span slot="title">
            <span>{{ $t('Navbar.' + item.label) }}</span>
          </span>
          <a-menu-item :key="index + '-' + subInd" v-for="(sub, subInd) in item.subMenu" @click="onClickMenu(sub)">
            <div class="flex align-center">
              <span class="dot"></span>
              {{ $t('Navbar.' + sub.label) }}
            </div>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      navList: [
        { label: this.$t("Common.DASHBOARD"), icon: 'home', path: '/' },
        // { path: '/notification', label: this.$t('Common.NOTIFICATION') },
        { path: '/wallet', label: this.$t("Common.WALLET") },
        // { path: '/invest', label: this.$t('Common.Subscription') },
        // { path: '/divestment', label: this.$t('Common.Assets Listing') },
        { path: '/portfolio', label: this.$t("Common.PORTFOLIO") },
        // { path: '/report', label: 'Transaction History' },
        { path: '/profile', label: this.$t("Common.PROFILE") },
        { path: '/referral', label: this.$t("Common.REFERRAL PROGRAM") },
        // { path: '/tree', label: 'Community' },
        // { path: '/arbitrage', label: 'Arbitrage Transaction Record' },
        // { path: 'logout', label: this.$t("Common.Sign Out") },
      ],
      defaultKey: 0
    }
  },
  methods: {
    onClickMenu({ label, path }) {
      if(path === 'logout') {
        this.onLogout();
      } else {
        this.$store.commit('SET_StorePageTitle', label);
        this.$router.push(path);
      }
    },
    onLogout() {
      localStorage.removeItem('token');
      this.$router.replace('/login');
    },
  },
  created() {
    let path = this.$route.path;
    this.navList.forEach((item, index) => {
      if (item.path) {
        if (path === item.path) {
          this.defaultKey = index;
          this.$store.commit('SET_StorePageTitle', item.label);
        }
      } else {
        item.subMenu.forEach((sub, subInd) => {
          if (sub.path === path) {
            this.$store.commit('SET_StorePageTitle', sub.label);
            this.defaultKey = index + '-' + subInd;
          }
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-menu {
  background: transparent;
  border: 0;
}

::v-deep .ant-menu-item {
  background-color: transparent !important;
  color: var(--navbar) !important;
  height: 50px !important;
  line-height: 50px !important;
  margin-bottom: 15px !important;

  &:hover {
    font-weight: bold;
    color: #fff;
  }

  &::after {
    border-right: 0 !important;
  }
}

::v-deep .ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0 20px !important;
}

::v-deep .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
  padding: 0 20px !important;
}

::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(64, 64, 64) !important;
  color: #fff !important;
  border-radius: 999px;
  display: inline-block;
  width: auto;
  padding-right: 35px;
  font-weight: bold;

  .anticon {
    color: var(--navbar);
  }

  &::after {
    border-right: 0;
  }
}

::v-deep .ant-menu-submenu-selected {
  color: #fff !important;
}


::v-deep .ant-menu-sub {
  background: transparent !important;
  border: 0;


}

::v-deep .ant-menu-submenu-title:hover {
  color: #fff !important;

  .ant-menu-submenu-arrow {
    &::after {
      background: #fff !important;
    }

    &::before {
      background: #fff !important;
    }
  }
}

::v-deep .ant-menu-submenu-title {
  .ant-menu-submenu-arrow {
    &::after {
      background: var(--navbar) !important;
    }

    &::before {
      background: var(--navbar) !important;
    }
  }
}


.ant-menu-submenu {
  color: var(--navbar);
  font-weight: bold;

  &:hover {
    font-weight: bold;
    color: #fff;
  }

  &::after {
    border-right: 0 !important;
  }

  .ant-menu-submenu-title {
    color: var(--navbar);
    font-weight: bold;

    &:hover {
      font-weight: bold !important;
      color: #fff !important;
    }

    &::after {
      border-right: 0 !important;
    }
  }
}


.navbar {
  overflow: hidden;
}

.dot {
  margin-right: 10px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: var(--navbar);
  border-radius: 50%;
}

.nav-text {
  font-size: 16px;
}

@media screen and (max-width: 576px) {

  .nav-text {
    font-size: 12px;
  }


  ::v-deep .ant-menu-inline .ant-menu-item {
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    font-size: 12px !important;
  }
}
</style>