import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/init.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        meta: { title: 'Home' }
      },
      {
        path: "/notification",
        name: "notification",
        component: () => import("@/views/notification/index.vue"),
        meta: { title: "NOTIFICATION" },
      },
      {
        path: "/details",
        name: "Details",
        component: () => import("@/views/notification/details.vue"),
        meta: { title: "DETAILS" },
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("@/views/wallet/index.vue"),
        meta: { title: "Wallet" },
      },
      // {
      //   path: "/invest",
      //   name: "Invest",
      //   component: () => import("@/views/invest/index.vue"),
      //   meta: { title: "Subscription" },
      // },
      // {
      //   path: "/divestment",
      //   name: "Divestment",
      //   component: () => import("@/views/divestment/index.vue"),
      //   meta: { title: "Assets Listing" },
      // },
      // {
      //   path: "/arbitrage",
      //   name: "Arbitrage",
      //   component: () => import("@/views/arbitrage/index.vue"),
      //   meta: { title: "Arbitrage Transaction Record" },
      // },
      {
        path: "/report/:coin",
        name: "report",
        component: () => import("@/views/assets/records.vue"),
        meta: { title: "Report" },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/index.vue"),
        meta: { title: "Profile" },
      },
      {
        path: "/reset-login-pwd",
        name: "ResetLoginPwd",
        component: () => import("@/views/profile/changeLoginPwd.vue"),
        meta: { title: "Reset Login Password" },
      },
      {
        path: "/reset-transaction-pwd",
        name: "ResetTransactionPwd",
        component: () => import("@/views/profile/changeSecurityPwd.vue"),
        meta: { title: "Reset Transaction Password" },
      },
      // {
      //   path: "/tree",
      //   name: "Tree",
      //   component: () => import("@/views/inviteTree/index.vue"),
      //   meta: { title: "Tree" },
      // },
      {
        path: "/portfolio",
        name: "Portfolio",
        component: () => import("@/views/portfolio/index.vue"),
        meta: { title: "Portfolio" },
      },
      {
        path: "/referral",
        name: "Referral",
        component: () => import("@/views/profile/referral.vue"),
        meta: { title: "Referral" },
      },
      {
        path: "/forgot-transaction",
        name: "ForgotTransaction",
        component: () => import("@/views/profile/forgotSecurity.vue"),
        meta: { title: "Forgot" },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/register.vue'),
    meta: { title: 'Register' }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/user/forgot.vue'),
    meta: { title: 'Forgot' }
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/user/terms.vue"),
    meta: { title: "Terms" },
  },
  { path: '*', component: () => import("@/views/404.vue"), }
]

// 解决报错 NAVIGATION.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})


// 不需要登录页面 name
const LOGIN_PAGE_NAME = ['Login', 'Register', '404', 'Forgot', 'Terms'];

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (LOGIN_PAGE_NAME.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
})
export default router
