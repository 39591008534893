/**
 *  IP 信息
 */
let baseUrl, imageUrl, origin, socketUrl;

// const devUrl = 'http://217.79.252.98:21300';
const devUrl = 'https://login.ableresearchtech.com';

switch (process.env.NODE_ENV) {
  case "development":
    origin = 'https://login.ableresearchtech.com/';
    baseUrl = devUrl + "/zchp";
    imageUrl = devUrl + "/zchp/";
    socketUrl = ''

    break;
  case "production":
    origin = 'https://login.ableresearchtech.com/';
    baseUrl = "https://login.ableresearchtech.com/zchp";
    imageUrl = "https://login.ableresearchtech.com/zchp/";
    socketUrl = 'wss://login.ableresearchtech.com/ez'

    break;
}

export { baseUrl, imageUrl, origin, socketUrl };
