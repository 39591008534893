<template>
  <a-select v-model="tabSelect" @change="onSwitchLang" size="small">
    <a-select-option v-for="(value, key) in langList" :value="key" :key="key">
      {{ value }}
    </a-select-option>
  </a-select>

  <!-- <div class="lang flex align-center">
    <div class="lang-item" v-for="(value, key) in langList" @click="onSwitchLang(key)">{{ value }}</div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      langList: {
        "en-US": "EN",
        "zh-CN": "中文",
        // "ja-JP": "日本語",
        // "ko-KP": "한국어",
      },
      tabSelect: 'en-US'
    };
  },
  methods: {
    // 切换语言
    onSwitchLang(lang) {
      localStorage.setItem("lang", lang); // 设置语言包
      this.$message.success({
        content: this.$t("userLogin.切换成功"),
        duration: 3000,
      });

      window.location.reload();
    },

  },
  created() {
    let lang = localStorage.getItem('lang') || 'en-US';
    this.tabSelect = lang;
  },
};
</script>

<style lang="scss" scoped>
.ant-select {
  width: 100px !important;
  background-color: transparent !important;
}
::v-deep .ant-select-selection {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}
.lang {
  color: #fff;
  height: 31px;
  line-height: 31px;
}
.lang-item {
  padding: 0 5px;
  font-size: 12px;
  border-right: 1px solid #fff;
  color: #fff;
  line-height: 16px;
  cursor: pointer;

  &:last-child {
    border: 0;
  }
}

@media screen and (max-width: 576px) {
  .ant-select {
    width: 80px !important;
    background-color: transparent !important;
  }
  .show {
    display: block;
    margin-right: 10px;
  }

  .currentTime {
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
  }
}
</style>
